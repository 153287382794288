.accessoryContainer {
  display: flex;
  flex-direction: row;
  height: 130px;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
  
}
.accessoryWrapper {
  width: 80%;
  height: auto;
  background-color: #f2f2f4;
  display: flex;
  justify-content: space-between;
  padding: 40px;
  border-radius: 7.5px;

  img {
    width: 60px;
    height: auto;
  }

  .priceAndDetailsWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .detailsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .textWrapper {
      margin-left: 50px;
    }
  }

  .name {
    color: $blackColor;
  }

  .disabled {
    color: $tertiaryColor;
  }

  .description {
    color: #66686c;
  }

  p {
    margin: 0;
    font-weight: 400;
  }

  .priceWrapper {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
  }
}

@include phone {
  .accessoryContainer {
    display: flex;
    flex-direction: row;

    .accessoryWrapper {
      width: 70%;
      padding: 15px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .name {
      font-size: 12px;
    }

    .priceAndDetailsWrapper {
      flex-direction: column;
      justify-content: flex-start;

      .mobileWrapper {
        display: flex;
        flex-direction: column-reverse;
        padding-left: 50px;
        padding-top: 10px;
      }
      .detailsWrapper {
        img {
          width: 30px;
        }

        .textWrapper {
          margin-left: 20px;

          .name {
            font-size: 12px;
            line-height: 15px;
          }
        }

        .description {
          font-size: 10px;
        }
      }
      .priceWrapper {
        padding-left: 50px;
        align-items: flex-start;
      }
    }

    .priceContainer {
      float: right;
      position: relative;
      top: 0;
      font-size: 10px;
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media (max-width: 1110px) and (max-height: 763px) and (orientation: landscape) {
  .accessoryContainer .priceContainer {
    width: 50%;
  }
}
