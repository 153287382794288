.termsPage {
  .terms {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto 150px;
    padding-top: 90px;

    .textarea {
      margin: 1vh;
      box-shadow: 0px 0px 0px 20px #fff inset;
      width: 80vw;
      padding: 20px 20px 20px 20px;
      font-family: $primaryFontFamily;
      font-weight: 400;
      font-size: 14px;
      color: $primaryColor;
      overflow: auto;
      align-self: center;

      .userAgreementPA {
        .toggle-processing {
          font-size: 12pt;
        }

        .link {
          font-size: 13px;
          letter-spacing: inherit;
        }
      }
    }

    .text {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }
  td,
  th {
    padding: 5px;
  }
}

@include phone {
  .shop-header {
    position: relative;
  }
  .termsPage {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    .terms {
      padding-top: 10px;
      margin: 0 auto 270px;
      overflow: hidden;

      .checkboxes {
        padding-left: 0;

        .userAgreement,
        .pricing {
          display: flex;
          align-items: center;
          flex-direction: row;
          height: 50px;
          margin-bottom: 10px;
          width: 100%;
        }
      }

      .terms-container {
        border: 2px solid #d7d8da;
        border-radius: 6px;
        margin: 0 11px 20px 11px;
        overflow-y: scroll;
        overflow-x: hidden;
      }

      .textarea {
        margin: 0;
        box-shadow: none;
      }
    }
  }
}

.checkboxes {
  .text {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .userAgreement,
  .pricing {
    display: flex;
  }

  .pdf {
    padding-left: 15px;
    font-size: 14px;
    letter-spacing: 0;
    font-family: $primaryFontFamily;
  }
}
