.signInContainer {
  transition: transform 1s;
}

.customDelay {
  animation-delay: 500ms

}

.authDialog {
  position: relative;
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: auto;

  .animateContainer {
    width: 100%;
    max-width: 26.875rem;
  }

  .signInContainer {
    background-color: #E5E5E7;
    width: 100%;
    height: 700px;
    position: absolute;
    top: 0;
    z-index: 20;
    overflow: hidden;
    max-width: 26.875rem;
    border-radius: 5px 0 0 5px;

    &.moveContainer {
      transform: translateX(431px);
      border-radius: 0 5px 5px 0;
    }
    button svg {
      padding-left: 5px;
      fill: $payAnywhereColor;
      width: 20px;
    }
  }

  .containerWrapper {
    width: 100%;
    height: 100%;
    position: relative;
    background: 0 0;
  }

  .animateText {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .containerTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    h1 {
      max-width: 100%;
      color: $blackColor;
      font-size: 18px;
      letter-spacing: 0;
      margin: 30px 0 10px;
      text-align: center;
      font-family: $primaryFontFamily;
      font-weight: 500;
    }

    p {
      width: 80%;
      max-width: 350px;
      color: $blackColor;
      font-size: 14px;
      letter-spacing: 0;
      text-align: center;
      font-family: $primaryFontFamily;
      font-weight: 400;
    }

    button {
      width: 90%;

      svg {
        padding-left: 5px;
        fill: $payAnywhereColor;
        width: 20px;
      }
    }
  }

  .authButton {
    border: 1px solid white;
    padding: 15px;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    width: 150px;
    text-align: center;
  }

  .formContainer {
    transition: transform 1s;
    width: 430px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
    justify-content: center;

    &.signin {
      transform: translateX(270px);
      height: 100%;
      @media screen and (max-height: 680px) {
        justify-content: flex-start;
      }

    }

    .inputContainer {
      width: 350px;
      text-align: left;
    }
  }

  .signInForm {
    padding: 20px 10px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .signInContainerButton a {
      color: $payAnywhereColor;
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    width: 200px;
    align-items: center;

    button {
      margin-top: 15px;
    }
  }
}

.mobileAuthDialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  overflow: auto;
  padding-top: 50px;

  h1 {
    font-size: 24px;
    letter-spacing: 0.5px;
    text-align: center;
  }

  .buttonContainer {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    a + button {
      margin-top: 20px
    }
  }

  .signInForm {
    width: 90%;
  }
}

.mobileLogin {
  padding: 20px 10px;

  p {
    font-weight: 500;
    font-size: 13px;
    color: black;
    text-align: center;
  }

  span {
    color: $payAnywhereColor
  }
}
@include phone {
  .buttonContainer {
    width: 80%;
  }

  .signUpContainerButton {
    min-height: 72px;
  }
}
