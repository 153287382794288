.fileInput {
  display: flex;
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formInput {
  font-family: $secondaryFontFamily !important;
  border: 0px;
  outline: none;
  padding-bottom: 3px;
  width: 100%;
}

.formLabel {
  font-family: $secondaryFontFamily !important;
  z-index: 1;
  font-weight: initial;
  font-size: 16px;
}

.dropZoneText {
  position: relative;
  top: 55px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.attachments {
  margin: 0 auto;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: baseline;

  .fileError {
    color: #dd0000
  }
}

.fileInput .attachmentIcon, .title {
  display: inline;
  padding-top: 10px;
}
