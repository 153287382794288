.orderSummaryContainer {

  .orderHeading {
    font-family: $primaryFontFamily;
    font-weight: 500;
    color: black;
    padding-left: 20px;
    margin-bottom: 0;
  }

  .grayContainer {
    width: 100%;
    min-height: 85px;
    background-color: $wrapperColor;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    margin-bottom: 10px;
    border-radius: 7px;
  }

  .name, .price {
    color: $blackColor;
    font-weight: 500;
    margin: 0;
  }

  .description {
    color: $secondaryColor;
  }

  .paymentPlanContainer {
    .detailsWrapper {
      display: flex;
      justify-content: initial;
      align-items: center;

      .icon {
        background-color: $payAnywhereColor;
        height: 40px;
        width: 40px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        border-radius: 20px;

        span {
          color: white;
        }
      }

      .icon + .name {
        padding-left: 20px;
      }
    }
  }

  .paymentEquipmentContainer, .paymentAccessoryContainer {
    .detailsWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .imageContainer {
        display: flex;
        align-items: center;
      }

      .imageContainer + .nameContainer {
        padding-left: 20px;
      }

      img {
        width: 50px;
        height: 50px;
      }

      p {
        margin: 0
      }
    }

    .nameContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      @media (max-width: 1440px) {
        flex-direction: column;
        font-size: 14px;
      }
    }
  }

  .cost {
    font-family: $primaryFontFamily;
    font-weight: 500;
    color: black;
    padding: 10px 20px;
    width: 100%;

    .subtotal, .tax, .total {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .subtotal, .tax {
      p {
        margin-bottom: 0;
      }
    }
  }

  @include phone {
    width: 100%;
    display: inline-block;
    margin: auto;
    padding-bottom: 50px;

    .paymentPlanContainer {
      width: 100%;
    }

    .planContainer {
      height: auto;
      padding: 15px;
      flex-direction: column;

      .description {
        font-size: 10px;
      }

      .detailsWrapper {
        .textWrapper {
          margin-left: 20px;
        }

        .icon {
          height: 40px;
          width: 40px;
        }
      }
    }

    .paymentAccessoryContainer {
      width: 100%;
    }

    .paymentEquipmentContainer {
      width: 100%;
    }

    .equipmentWrapper {
      flex-direction: row;
      margin-bottom: 0;
    }

    .cost {
      width: 100%;
    }
  }

  @include tablet {
    width: 100%;
    display: inline-block;
    margin: auto;

    .paymentPlanContainer {
      width: 100%;
    }

    .paymentAccessoryContainer {
      width: 100%;
    }

    .paymentEquipmentContainer {
      width: 100%;
    }

    .cost {
      width: 100%;
    }
  }

}
