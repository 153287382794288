.planWrapper{
  padding-right: 35px;
}
.planContainer {
  width: 100%;
  height: 115px;
  background-color: #f2f2f4;
  display: flex;
  justify-content: space-between;
  padding: 40px;
  border-radius: 7.5px;
  margin-bottom: 15px;

  .detailsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .textWrapper {
      margin-left: 50px;
    }

    .icon {
      background-color: $payAnywhereColor;
      height: 60px;
      width: 60px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      border-radius: 30px;
    }
  }

  .name {
    color: $blackColor
  }

  .description {
    color: #66686C
  }

  p {
    font-weight: 400;
    margin: 0;
  }

  .contactWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      font-size: 16px;
      letter-spacing: 0;
      color: $payAnywhereColor;
      
      svg {
        padding-left: 5px;
        fill: $payAnywhereColor;
        width: 20px;
      }
    }

    p {
      margin-right: 20px;
    }
  }
}

@include phone {
  .planContainer {
    height: auto;
    padding: 15px;
    flex-direction: column;

    .name {
      font-size: 12px;
    }
    .description {
      font-size: 11px;
    }

    .detailsWrapper {
      .textWrapper {
        margin-left: 20px;
      }

      .icon {
        height: 40px;
        width: 40px;
      }
    }

    .contactWrapper {
      padding-left: 60px;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 10px;

      p, a {
        font-size: 12px;
      }
    }
  }
  .planWrapper {
    padding: 0 15px 0 0;

    .type {
      font-size: 12px;
      margin: 0 20px;
    }
  }
}

@include tablet {
  .planContainer .detailsWrapper .icon {
    width: 60px;
    height: 40px;
    border-radius: 20px;
  }
}
