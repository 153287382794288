.toastr.rrt-error, .toastr.rrt-success {
  background-color: #36322E !important;
}
 
.toastr.rrt-success {
  svg {
    margin-top: 10px;
    height: 32px !important;
    width: 32px !important;
    border-radius: 50%;
    background: radial-gradient(#fff 40%, transparent 60%) !important;
  }
}
 
.close-toastr {
  color: white !important;
}
 
.redux-toastr .toastr .rrt-left-container .toastr-icon {
  fill: $payAnywhereColor !important;
}
