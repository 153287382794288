.center-content {
  display: flex;
  justify-content: center;
  align-content: center;
}

.custom-dialog {
  padding: 24px;
  @include phone {
    overflow: hidden;
  }
}

.logoutDialogButtonContainer {
  padding: 15px 0;
  display: flex;
}

.hidden {
  display: none
}

.buttonContainer {
  width: 200px;

  &.center {
    margin: 0 auto;
  }

  &.stacked {
    width: 50%;
    display: flex;
    flex-direction: row;

    button + button {
      margin-left: 12px;
    }
  }
}

.popoverWrap {
  background-color: #404143;
  border-radius: 16px;
  color: #fff;
  cursor: default;
  display: inline-block;
  font-family: 'Helvetica', sans-serif;
  font-size: 13px;
  font-weight: bold;
  position: relative;
  text-align: center;
  width: 20px;

  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    bottom: 30px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    display: none;
    font-size: 12px;
    font-family: 'Helvetica', sans-serif;
    left: -95px;
    padding: 7px 10px;
    position: absolute;
    width: 210px;
    z-index: 4;
    height: 80px;
    top: -90px;

    &:before {
      border-top: 7px solid rgba(0, 0, 0, 0.85);
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      bottom: -7px;
      content: '';
      display: block;
      left: 50%;
      margin-left: -7px;
      position: absolute;
    }
  }

  &:hover {
    .popover {
      display: block;
      -webkit-animation: fade-in .3s linear 1;
      -moz-animation: fade-in .3s linear 1;
      -ms-animation: fade-in .3s linear 1;
    }
  }

  @include tablet {
    .popover {
      left: -180px;

      &:before {
        left: 90%;
      }
    }
  }
  @include phone {
    .popover {
      left: -180px;

      &:before {
        left: 90%;
      }
    }
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.authImage {
  height: 140px;
  width: auto;
}

.text-orange {
  color: $payAnywhereColor
}

#exhibitAToggle {
  cursor: pointer;
}

.error {
  color: red
}

.flexContainer {
  display: flex;
}
