@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-Black.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-BlackItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-Book.otf") format("opentype");
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-BookItalic.otf") format("opentype");
  font-style: italic;
}


@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-Thin.otf") format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-ThinItalic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-Ultra.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: Gotham;
  src: url("../fonts/Gotham/Gotham-UltraItalic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}
