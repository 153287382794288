@import '/srv/app/paapply/client/src/styles/Variables';
                @import '/srv/app/paapply/client/src/styles/Mixins';
.pa-circle-button {
  width: 50px;
  height: 50px;
  color: $stepper !important;
}

.pa-circle-button.checked {
  color: $payAnywhereColor !important;
}