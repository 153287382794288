.toggleWrapper {
  display: flex;
  align-items: center;
  width: 35%;
  @include phone {
    width: 100%;
  }
}

.toggleContainer {
  cursor: pointer;
  display: flex;
  background-color: white;
  min-width: 130px;
  justify-content: space-between;
  border-radius: 65px;
  height: 25px;
  z-index: 1;
}

.toggleSnackbar {
  background: #404143;
  color: white;
  display: flex;
  width: 65px;
  height: 25px;
  border-radius: 32.5px;
  transition: all 0.5s;
  position: absolute;

  &.active {
    transform: translateX(65px);
  }
}

.text {
  font-weight: 400;
  z-index: 10;
  color: $blackColor;
  font-size: 14px;
  display: flex;
  align-content: center;
  align-items: center;
  width: 65px;
  justify-content: center;

  &.active {
    color: white;
  }
}

@include phone {
  .toggleContainer {
    float: left;
    height: 15px;
    margin-top: 5px;
    width: 80px;
    min-width: 60px;

    .text {
      font-size: 10px;
      padding: 0 10px;
    }
  }
  .toggleSnackbar {
    height: 15px;
    width: 40px;

    &.active {
      transform: translateX(40px);
    }
  }
}
// e500
@media (max-width: 999px) and (max-height: 916px) and (orientation: landscape) {
  .mobileWrapper {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }
}

//e600
@media (max-width: 1280px) and (max-height: 632px) and (orientation: landscape) {
  .mobileWrapper {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }
}
