.footer {
  display: flex;
  align-items: center;
  background-color: white;
  height: 120px;
  box-shadow: 0px -7px 7px -2px rgba(204, 204, 204, 1);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: flex-end;
  padding-right: 55px;
  z-index: 100;
  padding-left: 55px;

  &.sideComponent {
    justify-content: space-between;
  }

  &RightContainer {
    display: flex;
  }

  &LeftContainer {
    display: flex;
  }
}

.shoppingBagIcon {
  position: relative;
  margin-right: 35px;
  margin-top: 5px;

  .counter {
    position: absolute;
    top: -10px;
    right: -10px;
    color: white;
    background: $payAnywhereGradient;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
}

.backContainer {
  width: 200px;
  margin-right: 8px;
}

@include phone {
  .footer {
    padding: 30px;
    height: auto;

    &LeftContainer {
      border-bottom: 1px solid $lineColor;
      margin-bottom: 15px;
    }

    &.counter, &.backAction {
      justify-content: space-between;
    }
    &.backAction {
      & .footerRightContainer {
        width: 100%;
        justify-content: space-between;
      }
    }
    &.sideComponent{
      flex-direction: column;
    }
  }
  .buttonContainer {
    width: 150px;
  }
  .backContainer {
    width: 60px;
  }
  .isTerms {
    box-shadow: none;
    padding: 30px 0;
    .footerLeftContainer {
      border: none;
      padding: 0 30px 0 15px;
      .checkboxes .text {
        font-size: 12px;
      }
    }
    .footerRightContainer {
      box-shadow: 0px -7px 7px -2px #cccccc;
      width: 100%;
      justify-content: space-between;
      padding: 30px 30px 0;
    }
  }
}
