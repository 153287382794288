.pa-button {
  background: $payAnywhereGradient;
  width: 100%;
  height: 60px;
  border-radius: 30px !important;
  box-shadow: 10px 12px 6px -6px #cccccc;

  &:disabled {
    background: #d8d8da;
    cursor: default;

    &:hover {
      box-shadow: none;
    }
  }

  &.fullWidth {
    width: 100%;
  }
}

.pa-button-label {
  color: $backgroundColor;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 16px;
  text-transform: capitalize;
  @include phone {
    font-size: 14px;
  }
}


.pa-button-flat {
  background: white;
  width: 100%;
  height: 60px;
  border-radius: 30px !important;

  &:hover {
    background-color: white!important;
  }

  &.fullWidth {
    width: 100%;
  }
}

.pa-button-flat-label {
  color: $payAnywhereColor;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 16px;
  text-transform: capitalize;
}

.closeButton{
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $payAnywhereColor;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
  0px 24px 38px 3px rgba(0, 0, 0, 0.14),
  0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  z-index: 100;
  @include phone {
    right: 30px;
  }
}
