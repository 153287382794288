@import 'Variables';

.column {
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 46px;
  line-height: 3rem;
  color: $primaryColor;
}

.boxSize {
  max-height: 150px;
  min-height: 150px;
  justify-content: space-between;
}

.boxMargin {
  margin: 20px;
}

.boxPadding {
  padding: 30px 0;
}

.boxHeaderColor {
  color: $dialogSecondBoxTextColor;
}

.boxBackground {
  background-color: $dialogSecondBoxbackgroundColor;
}

.phoneNumber {
  color: $payAnywhereColor;
}

.closeButton {
  @include closeButton;
}