.thankYouPage {

  .thankYou {
    height: 100vh;
    width: 90%;
    padding-top: 50px;
    margin: 0 auto;

    .container {
      display: flex;
      flex-flow: row wrap;
      justify-content:center;
      align-items: center;
      margin: auto;
      width: 90%;
      height: 45vh;
      padding: 7% 0 0 0;

      @include phone {
        width: 100%;
        align-items: flex-start;
        height: 75vh;
      }

      @include tablet {
        display: inline-block;
        width: 100%;
        height: 70vh;
      }

      .container>* {
        flex: 1 1 auto;
      }

      .columnOne {
        width: 40%;
        margin-right: 30px;

        @include phone {
          width: 100%;
          text-align: center;
          margin: 0;
        }

        @include tablet {
          width: 75%;
          margin-right: auto;
          margin-left: auto;
          text-align: center;
        }

        .contact {
          margin: 30px 0 30px 0;
          color: $payAnywhereColor;
          font-weight: 700;
          font-family: $secondaryFontFamily;
          display: flex;
          flex-direction: column;

          @include desktop {
            @media (max-width: 1279px) {
              font-size: 12px;
            }

          }

          @include phone {
            justify-content: center;
            text-align: center;
            font-size: 13px;
            margin: 30px 0 0 0;
            a {
              font-size: 13px;
            }
          }

          @include tablet {
            justify-content: center;
            text-align: center;
            font-size: 13px;
            margin: 30px 0 50px 0;
          }

        }

        .textContainer {
          flex-basis: 30%;
        }

        .innerText {
          font-family: $primaryFontFamily;
          color: $secondaryColor;
          padding-top: 2vh;
          font-weight: 400;
          font-size: 12pt;
          line-height: 1.3;

          @include phone {
            font-size: 13px;
          }

          @include tablet {
            font-size: 13px;
          }
        }

        .header {
          font-weight: 700;
          font-size: 36px;
          letter-spacing: 1pt;

          @include desktop {
            @media (max-width: 1279px) {
              font-size: 30px;
            }

            @media (min-width: 1479px) {
              white-space: pre-line;
            }

          }

          @include phone {
            text-align: center;
            font-size: 30px;
            white-space: normal;
          }

          @include tablet {
            text-align: center;
            font-size: 30px;
            white-space: pre-line;
          }
        }

      }

      .columnTwo {
        flex-grow: 1;
        flex-shrink: 1;
        align-self: center;
        width: 55%;

        @include phone {
          margin: auto;
          width: 80%;
        }

        @include tablet {
          margin: auto;
        }

        @include desktop {
          @media (min-width: 1279px) {
            padding-top: 35px;
          }

        }

      }

      .buttonContainer {
        margin: auto;
        padding-top: 8%;
      }

      .pa-button {
        width: 200px;
        height: 60px;

        @include phone {
          width: 150px;
        }
      }

      .pa-button-label {
        text-transform: none !important;
      }

    }


  }
}
