.shoppingBag {
  width: 90%;
  margin: 0 auto 250px;
  padding-top: 90px;

  .productsWrapper {
    margin: 0 35px;
    @include phone {
      margin: 0;
    }
  }

  .type {
    margin-left: 40px;
    font-weight: 500;
    color: $blackColor;
  }

  .introText {
    margin-bottom: 100px;
  }

}

.trashCanIcon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 115px;
  padding-left: 18px;
}
.trashCanIconPlaceholder{
  width: 35px;
}

@include phone {
  .shoppingBag {
    padding-top: 110px;
    .introText {
      margin-bottom: 50px;
      p {
        font-size: 12px;
      }

      h1 {
        font-size: 30px;
      }
    }

    .type {
      font-size: 12px;
      margin: 0 20px;
    }
  }
  .trashCanIcon {
    height: 100%;
    align-self: center;
    padding: 0 7px 15px 10px;
    margin-right: -15px;
  }

  .trashCanIconPlaceholder {
    margin-right: -15px;
  }

  .footer {
    box-shadow: 0px -7px 7px -2px #cccccc;

    .footerRightContainer {
      box-shadow: none;
    }
  }
}

.noEquipmentDialog {
  button > span {
    text-transform: none;
  }
}