.payment {
  padding-top: 90px;

  .bodyContainer {
    width: 100%;

    .bodyWrapper {
      width: 80%;
      display: inline-flex;
      margin-left: 10%;
      margin-right: 10%;
      justify-content: center;
      overflow: auto;
      padding-bottom: 120px;
      height: auto;

      .creditCardWrapper {
        width: 60%;

        .paymentText {
          font-family: $primaryFontFamily;
          font-weight: 450;
          color: black;
          padding-left: 20px;
          margin-bottom: -10px;
        }
      }
      .orderWrapper {
        width: 40%;
        margin-left: 5%;
      }
    }
  }

  @include phone {
    padding-top: 110px;
    .title {
      padding: 0 0 0 0;
      font-size: 14pt;
    }
    .bodyContainer {
      .bodyWrapper {
        display: inline-block;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 75px;

        .creditCardWrapper {
          width: 100%;
        }

        .orderWrapper {
          width: 100%;
          margin: 0;
        }
      }
    }
    .orderSummaryContainer .grayContainer {
      width: 100%;
    }
  }
  @include tablet {
    .bodyContainer {
      .bodyWrapper {
        display: inline-block;
        width: 90%;
        margin-left: 3%;
        margin-right: 7%;

        .creditCardWrapper {
          width: 70%;
          float: left;
        }

        .orderWrapper {
          float: right;
          width: 30%;
          display: block;
          margin: auto;
        }
      }
    }
  }
}
