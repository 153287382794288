.custom-dialog {
  .action {
    cursor: pointer;
    color: $payAnywhereColor;
    font-weight: 500;
    line-height: 1.75;
  }
}

.hidden-dialog {
  visibility: hidden;
}