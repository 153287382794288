h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $primaryFontFamily;
}

p, span {
  font-family: $primaryFontFamily;
}

.introText {
  text-align: center;

  p {
    font-weight: 500;
    font-family: $primaryFontFamily;
  }

  h1 {
    margin-top: 0;
    font-size: 29px;
  }
}

@include phone {
  .introText {
    p {
      font-size: 12px;
    }

    h1 {
      font-size: 30px;
    }
  }
}
