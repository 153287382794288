.clientForm,
.paymentForm,
.businessForm {
  .fieldsWrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;

    .addBorder {
      margin-top: 10px;

      fieldset {
        border-width: 3px;
      }
    }
  }
}

.clientForm,
.businessForm {
  padding-top: 90px;

  .introText {
    padding-bottom: 85px;
  }
}

.clientFormContainer,
.businessFormContainer {
  width: 75%;
  justify-content: center;
  margin: 0 auto;
}

.clientFormContainer + .orderWrapper,
.businessFormContainer + .orderWrapper {
  padding-left: 60px;
}

.clientWrapper,
.businessWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  width: 90%;

  .orderWrapper {
    width: 25%;
  }
}

.inputLabel {
  font-family: $primaryFontFamily;
  font-weight: 450;
  color: black;
  padding-left: 20px;
  margin-bottom: -10px;
}

.formRow {
  display: flex;
  flex-direction: row;
}

.formInput {
  width: 100%;

  &.smallBox {
    position: relative;
    width: 30%;

    .timeZone {
      position: absolute;
      top: 50px;
      margin: 0;
      right: 48px;
      font-weight: 450;
      color: black;
      font-size: 15px;
    }
  }

  &.cardImage {
    justify-content: center;
    width: 33%;
    align-items: center;
    margin-top: 25px;
    height: 70px;
    display: flex;
    align-content: center;

    img {
      width: 50px;
      height: 30px;
    }
  }

  &.cardNumber {
    display: flex;
    flex-direction: row;

    .cardContainer {
      width: 90%;
    }

    .cardVisibilityContainer {
      margin-top: 25px;
      height: 70px;
      display: flex;
      align-content: center;
    }
  }
}

.formInput + .formInput {
  margin-left: 30px;
}

.ownerLabel {
  cursor: pointer;
  font-family: $primaryFontFamily !important;
  font-weight: bold;
  letter-spacing: 0.15px;
  @include font-size(25);
}

.radioLabel {
  font-size: 16px !important;
  color: $blackColor !important;
  font-weight: 400 !important;
}

.additionalOwnerHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: $payAnywhereColor;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  svg + span {
    padding-left: 10px;
  }
}

.addOwnerRadio {
  cursor: pointer;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  color: $payAnywhereColor;
  font-weight: 500;

  span + span {
    margin-left: 12px;
  }
}

.federalTax {
  position: relative;

  .placement {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

.loginErrorMessage,
.signupErrorMessage {
  color: red;
  width: 350px;
  font-size: 15px;
  font-weight: bold;
}

@include phone {
  .formRow {
    flex-direction: column;
    &.personal-data {
      order: -1;
    }
  }
  .formInput + .formInput {
    margin-left: 0;
  }
  .formInput.cardImage {
    justify-content: flex-start;
  }
  .formInput.smallBox {
    width: 100%;
  }
  .paymentForm .fieldsWrapper {
    padding-bottom: 50px;
  }
  .clientForm,
  .businessForm {
    padding-top: 110px;
    .introText {
      padding-bottom: 0;
    }
  }
  .clientWrapper,
  .businessWrapper {
    flex-direction: column;

    .orderWrapper {
      width: 100%;
      padding-left: 0;
      padding-bottom: 110px;
    }
  }
  .clientFormContainer,
  .businessFormContainer {
    width: 100%;
  }
  .addOwnerRadio {
    justify-content: flex-start;
  }
  .federalTax .placement {
    right: 10px;
  }
  .buttonContainer.twoFactor {
    width: 100%;
  }
}

@include desktop {
  .formInput {
    &.stacked {
      width: 50%;
    }
  }
  .formRow {
    &.stacked {
      width: 50%;
      margin-left: 30px;
    }
  }
}

@include tablet {
  .clientWrapper,
  .businessWrapper {
    flex-direction: column;

    .orderWrapper {
      width: 100%;
      padding-left: 0;
      padding-bottom: 110px;
    }
  }
  .clientFormContainer,
  .businessFormContainer {
    width: 100%;
  }
  .formInput {
    &.stacked {
      width: 50%;
    }
  }
  .formRow {
    &.stacked {
      width: 50%;
      margin-left: 30px;
    }
  }
  .inputLabel {
    font-size: 14px;
  }
  .federalTax .placement {
    right: -10px;
  }
}

.buttonContainer.idologyForm {
  padding: 0 100px;
  width: 100%;
}
.buttonContainer.twoFactor {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
}

.filesForm {
  padding-bottom: 15px;
}

.bottomSpacer {
  margin-bottom: 16px;
}

.businessPercentNote {
  display: flex;
  flex-direction: column;
  padding-left: 20px;

  .error {
    color: #d32f2f;
  }

  p {
    margin-bottom: 4px;
  }
}
