.stepper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .step {
    margin-right: 7.5px;
    width: 45px;
    height: 5px;
    background-color: #E5E5E7;

    &.active {
      background-color: $payAnywhereColor;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
