.equipmentContainer {
  display: flex;
  flex-direction: row;
  height: 130px;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
}
.accesoriesWrapper {
  padding-top: 20px;
}
.equipmentWrapper {
  width: 80%;
  height: auto;
  background-color: #f2f2f4;
  display: flex;
  justify-content: space-between;
  padding: 40px;
  border-radius: 7.5px;

  img {
    width: 60px;
    height: auto;
  }

  .priceAndDetailsWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .detailsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .textWrapper {
      margin-left: 50px;
    }
  }

  .name {
    color: $blackColor;
  }

  .disabled {
    color: $tertiaryColor;
  }

  .description {
    color: #66686c;
  }

  .shippingDescription {
    font-size: 14px;
    line-height: 26px;
    color: #66686c;
  }

  p {
    margin: 0;
    font-weight: 400;
  }

  .priceWrapper {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
  }
}

.textCenter {
  text-align: center;
}

@include phone {
  .equipmentContainer {
    height: 100%;
    min-height: 130px;
  }

  .accesoriesWrapper,
  .hardwareWrapper {
    padding-top: 20px;
  }

  .equipmentWrapper {
    width: 70%;
    min-height: 130px;
    padding: 15px;
    flex-direction: column;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .name {
      font-size: 12px;
    }
    .description {
      font-size: 10px;
    }

    .priceWrapper {
      font-size: 10px;
      align-items: center;
      padding: 15px 0 5px 50px;

      p {
        width: 100%;
      }
    }

    .detailsWrapper {
      .textWrapper {
        margin-left: 20px;
      }
    }

    img {
      width: 30px;
    }

    .priceAndDetailsWrapper {
      flex-direction: column;
    }
  }
}

@include tablet {
  .equipmentContainer {
    height: 100%;
    min-height: 130px;
  }

  .priceWrapper {
    .description {
      margin-left: 10px;
    }
  }
}
