.shop-header {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 10;
  top: 0;
  .logo{
    width: 120px;
    height: 70px;
    background: url('../images/logo.jpg') no-repeat;
    background-size: contain;
  }
}

@include phone {
  .shop-header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;

    .logo {
      width: 80px;
      height: 80px;
    }
  }
}
