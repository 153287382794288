.counterWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f2f2f4;
  border-radius: 7.5px;
  margin-left: 2%;
  width: 18%;

  .counterContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .counter {
    color: $blackColor;
    border: 5px solid #E6E6EA;
    width: auto;
    display: flex;
    height: 45px;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    font-weight: 500;
  }

  .counterButton {
    color: #404143
  }

  .counterMessage {
    font-weight: 600;
    cursor: pointer;
    color: $payAnywhereColor;
  }

  .counterInput {
    max-width: 48px;

    input {
      text-align: center;
    }
  }
}

@include phone {
  .counterWrapper {
    height: auto;
    justify-content: flex-end;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0;
    padding: 21px 13px;
    width: 30%;

    .counterMessage {
      font-size: 10px;
      padding-right: 35px;
    }

    .counterContainer {
      align-self: flex-start;
      align-items: center;
      position: relative;
      left: auto;
    }

    .counterButton {
      width: 14px;
      padding: 0;
    }

    .counter {
      margin: 0 6px;
      height: 37.5px;
      border-width: 3px;
      border-radius: 6px;
    }

    .counterInput {
      min-width: 42px;
      max-width: 48px;
    }
  }
}

@include tablet {
  .counterWrapper {
    padding: 13px;
    width: 15%;
  }
  .counterContainer {
    height: auto;
  }

  .counterInput {
    min-width: 23px;
  }
}
